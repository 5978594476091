import React, { useState } from "react";
import Logo from "../images/logo.png";
import LogoIcon from "../images/icon-transparent.svg";
import { navigate } from "gatsby";
import { gsap } from "gsap";

const navLinks = [
  { name: "Home", route: "/" },
  { name: "Approach ", route: "/approach" },
  { name: "Services", route: "/services" },
  { name: "Case Studies", route: "/caseStudies" },
  { name: "Insights", route: "/insights" },
  { name: "About", route: "/about" },
  { name: "Contact", route: "/contact" },
];

export default function Nav({ changeTheme, theme }) {
  const [hidden, setHidden] = useState(true);
  // const overlayPath = useRef(null);

  const expandMenu = () => {
    setHidden(!hidden);
    document.body.classList.add("no-scroll");
    gsap
      .timeline()
      .set(".overlay__path", {
        attr: { d: "M 0 0 V 0 Q 50 0 100 0 V 0 z" },
      })
      .to(
        ".overlay__path",
        {
          duration: 0.8,
          ease: "power4.in",
          attr: { d: "M 0 0 V 50 Q 50 100 100 50 V 0 z" },
        },
        0
      )
      .to(".overlay__path", {
        duration: 0.3,
        ease: "power2",
        attr: { d: "M 0 0 V 100 Q 50 100 100 100 V 0 z" },
        onComplete: () => {
          gsap.to(".link", { opacity: 1, duration: 0.8, stagger: 0.14 });
        },
      });
  };
  const collapseMenu = () => {
    console.log("CALLED");
    gsap.to(".link", {
      opacity: 0,
      duration: 0.8,
      stagger: 0.14,
      onComplete: () => {
        gsap
          .timeline()
          .set("#overlayPath", {
            attr: { d: "M 0 100 V 0 Q 50 0 100 0 V 100 z" },
          })
          .to("#overlayPath", {
            duration: 0.3,
            ease: "power2.in",
            attr: { d: "M 0 100 V 50 Q 50 100 100 50 V 100 z" },
          })
          .to("#overlayPath", {
            duration: 0.8,
            ease: "power4",
            attr: { d: "M 0 100 V 100 Q 50 100 100 100 V 100 z" },
            onComplete: () => {
              setHidden(!hidden);
              document.body.classList.remove("no-scroll");
            },
          });
      },
    });
  };
  const menuNavigaror = (route) => {
    navigate(route);
    collapseMenu();
  };

  const modeSwitcher = () => {
    gsap
      .timeline()
      .set("#outer", { display: "block" })
      .set(".overlay__path_m", {
        attr: { d: "M 0 0 V 0 Q 50 0 100 0 V 0 z" },
      })
      .to(
        ".overlay__path_m",
        {
          duration: 0.8,
          ease: "power4.in",
          attr: { d: "M 0 0 V 50 Q 50 100 100 50 V 0 z" },
        },
        0
      )
      .to(".overlay__path_m", {
        duration: 0.3,
        ease: "power2",
        attr: { d: "M 0 0 V 100 Q 50 100 100 100 V 0 z" },
        onComplete: () => {
          changeTheme();
        },
      })
      .set(".overlay__path_m", {
        attr: { d: "M 0 100 V 0 Q 50 0 100 0 V 100 z" },
      })
      .to(".overlay__path_m", {
        duration: 0.3,
        ease: "power2.in",
        attr: { d: "M 0 100 V 50 Q 50 100 100 50 V 100 z" },
      })
      .to(".overlay__path_m", {
        duration: 0.8,
        ease: "power4",
        attr: { d: "M 0 100 V 100 Q 50 100 100 100 V 100 z" },
        onComplete: () => {
          gsap.set("#outer", { display: "none" });
        },
      });
  };

  return (
    <>
      <div className="w-screen  absolute top-0 p-8 left-0 right-0 z-50">
        <nav className="container mx-auto grid grid-cols-2">
          <div className="col-span-1 flex items-center justify-start">
            <img src={Logo} alt="Theorem Logo" className="h-10 lg:block hidden" />
            <img src={LogoIcon} alt="Theorem Logo" className="h-12 lg:hidden" />
          </div>
          <div className="flex relative z-50 items-center justify-end">
            <div
              onClick={() => modeSwitcher()}
              className={`w-fit  px-4 lg:py-1 py-2 text-xs lg:text-base uppercase lg:mr-8 mr-4 cursor-pointer flex items-center justify-center rounded-full ${theme === "dark" ? "bg-white text-black" : "bg-black text-white"}`}
            >
              {theme} Mode
            </div>
            <div onClick={() => expandMenu()} className="flex group items-center h-8 justify-center duration-200 transition flex-col cursor-pointer">
              <span className=" w-10 -translate-y-1 transition-all duration-200 group-hover:translate-y-0 border border-primary-100"></span>
              <span className=" w-10  border border-primary-100"></span>
              <span className=" w-10 translate-y-1 border transition-all duration-200 group-hover:translate-y-0 border-primary-100"></span>
            </div>
          </div>
        </nav>
      </div>
      <div className={`fixed inset-0  grid grid-cols-1 z-50 ${hidden ? "hidden" : ""}`}>
        <svg className="overlay w-full h-full" width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
          <path id="overlayPath" className="overlay__path" fill={theme === "dark" ? "white" : "black"} vectorEffect="non-scaling-stroke" d="M 0 100 V 100 Q 50 100 100 100 V 100 z" />
        </svg>
        <div className="w-screen absolute top-0 p-8 left-0 right-0 z-50">
          <nav className="container mx-auto grid grid-cols-2">
            <div className="col-span-1 flex items-center justify-start">
              <img src={Logo} alt="Theorem Logo" className="h-10 lg:block hidden" />
              <img src={LogoIcon} alt="Theorem Logo" className="h-12 lg:hidden" />
            </div>
            <div className="flex relative z-50 items-center justify-end">
              <div onClick={() => collapseMenu()} className="flex group items-center h-8 justify-center duration-200 transition flex-col cursor-pointer">
                <span className=" w-10 -translate-y-0 transition-all duration-200 group-hover:-translate-y-1 border border-primary-100"></span>
                <span className=" w-10  border border-primary-100"></span>
                <span className=" w-10 translate-y-0 border transition-all duration-200 group-hover:translate-y-1 border-primary-100"></span>
              </div>
            </div>
          </nav>
        </div>
        <div className={`absolute inset-0 col-span-1 py-32 helv font-light flex flex-col items-center justify-between `}>
          {navLinks.map((link, index) => {
            return (
              <div key={index} onClick={() => menuNavigaror(link.route)} className={`text-4xl cursor-pointer lg:text-5xl link opacity-0 w-full uppercase text-center ${theme === "light" ? "text-white" : "text-black"}`}>
                <span className="hover-line">{link.name}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div id="outer" className="mo absolute inset-0 z-50 hidden">
        <svg className="overlay w-full h-full" width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
          <path id="overlayPath" className="overlay__path_m" fill="black" vectorEffect="non-scaling-stroke" d="M 0 100 V 100 Q 50 100 100 100 V 100 z" />
        </svg>
      </div>
    </>
  );
}
