import React from "react";
import Logo from "../images/logo.png";

export default function Footer({ theme }) {
  return (
    <div className={`w-screen relative z-40 py-24  ${theme === "dark" ? "bg-white text-black" : "bg-black text-white"}`}>
      <div className="container mx-auto grid px-8 lg:grid-cols-7 grid-cols-2 gap-16">
        <div className="lg:col-span-4 col-span-2 flex flex-col">
          <p className="text-5xl leading-snug">
            We Would Love <br /> To Hear From You
          </p>
          <p className="text-xl opacity-50 lg:w-1/2 mt-4">Feel free to reach out if you want to collaborate with us, or simply have a chat.</p>
        </div>
        <div className="lg:col-span-1 col-span-2 pt-8 flex flex-col items-start justify-start">
          <p className="uppercase">Our ADdress</p>
          <p className="opacity-50 mt-4">2028 East Ben White Boulevard Suite 240-1989 Austin, Texas 78741</p>
        </div>
        <div className="lg:col-span-1 col-span-2 pt-8 flex flex-col items-start justify-start">
          <p className="uppercase">Follow Us</p>
          <div className="flex w-2/3 mt-4 items-center justify-between opacity-50">
            <a href="">Fb</a>
            <a href="">Ig</a>
            <a href="">Tw</a>
            <a href="">Li</a>
          </div>
        </div>
        <div className="lg:col-span-1 col-span-2 pt-8 flex flex-col items-start justify-start">
          <p className="uppercase">Home</p>
          <p className="uppercase opacity-50">Work</p>
          <p className="uppercase opacity-50">Services</p>
          <p className="uppercase opacity-50">Team</p>
          <p className="uppercase opacity-50">Contact</p>
        </div>
      </div>
      <div className="container mx-auto grid lg:grid-cols-7 px-8 grid-cols-2 gap-16">
        <div className="lg:col-span-4 grid-cols-2 pt-8">
          <div className="flex items-center  justify-start mt-8  text-2xl">
            <span className="flex">hello@theoremstudio.com</span>
            <div className=" pt-1 w-9 h-9 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 13H4v-2h8V4l8 8-8 8z" fill={`${theme !== "dark" ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)"}`} />
              </svg>
            </div>
          </div>
        </div>

        <div className="lg:col-span-3 col-span-2 lg:col-start-5 opacity-50 flex items-center justify-between">
          <a href="">Terms of Service</a>
          <a href="">Privacy Policy</a>
          <a href="">GDPR Policy</a>
          <a href="">Cookie Policy</a>
        </div>
      </div>
      <hr className={`w-screen opacity-60 my-24 ${theme === "dark" ? "bg-black" : "bg-white"}`} />
      <div className="container px-8 mx-auto grid lg:grid-cols-7 grid-cols-4 gap-16">
        <div className="col-span-4 w-1/3 object-cover">
          <img className="w-full h-full" src={Logo} />
        </div>
        <div className="flex col-span-3 items-center justify-end">
          <p className=" opacity-75">&copy; 2022 Theorem Studio &mdash; All Rights Reserved </p>
        </div>
      </div>
    </div>
  );
}
